import React from 'react'
import { Link } from 'react-router-dom'
import AboutImg from '../../assets/images/forest.jpg'


function AboutComp() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ "min-height": "400px" }}>
                        <div className="position-relative h-100">
                            <img className="img-fluid position-absolute w-100 h-100" src={AboutImg} alt="" style={{ "object-fit": "cover" }} />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                        <h6 className="section-title bg-white text-start text-primary pe-3" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>About Us</h6>
                        <h1 className="mb-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Welcome to <span className="text-primary" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Lalpaharir Desh</span></h1>
                        <p className="mb-4" style={{ fontFamily: 'initial' }}>Need a break from the delightful symphony of car horns and construction noise? </p>
                        <p className="mb-4" style={{ fontFamily: 'initial' }}>Escape to Lalpaharir Desh, where the only sound you'll hear is nature’s annoying insistence on peace and quiet. A journey to Lalpaharir Desh is a journey into nature’s masterpiece—where the grandeur of the hills, the soothing flow of rivers, and the mystery of ancient forests weave together to offer an experience that feels otherworldly.</p>
                        <div className="row gy-2 gx-4 mb-4">
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Forest camps with bonfire.</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Treehouse, Matir Bari, resorts surrounded by nature.</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Activities for couples, friends and family.</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Bike rides, Car services.</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Adventure and 12 km trek.</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0" style={{ fontFamily: 'initial' }}><i className="fa fa-arrow-right text-primary me-2"></i>Wildlife Encounters</p>
                            </div>
                        </div>
                        <Link className="btn btn-primary py-3 px-5 mt-2" href="" style={{ fontFamily: 'initial' }}>Read More</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutComp