import React, { useState } from 'react'

const FAQ = [
    {
        "question": "How do I book a trip on your website?",
        "answer": "On booking page submit proper details and phone number, Our team will contact you.",
    },
    {
        "question": "Can I cancel or modify my booking?",
        "answer": "Yes, you can cancel or modify your booking. However, this will depend on the provider’s cancellation and modification policies. Some bookings allow free changes or cancellations, while others may charge a fee. Check the terms and conditions of your specific booking under contact or contact our customer service for assistance.",
    },
    {
        "question": "What payment methods do you accept?",
        "answer": "We accept UPI,CASH & Net Banking",
    },
    {
        "question": "How do I get a refund if I cancel my trip?",
        "answer": "Refunds are processed according to the cancellation policy of the service provider (airline, hotel, etc.). If your booking is eligible for a refund, it will typically be returned to your original payment method within 7-14 business days. For more details, check the specific terms related to your booking.",
    },
    {
        "question": "Can I book group trips or family vacations through your website?",
        "answer": "Absolutely! We offer special packages and discounts for group and family travel. If you’re traveling with a large group, contact our group booking team for personalized assistance in planning your trip.",
    },
    {
        "question": "What should I do if I encounter issues while traveling?",
        "answer": "If you experience issues while traveling, contact our 24/7 customer service team. We can assist with flight rebooking, hotel changes, emergency support, and more. You can also contact local authorities for immediate concerns.",
    },
]

function FaqComp() {
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className='container'>
                <div className="text-center">
                    <h6 className="section-title bg-white text-center text-primary px-3">FAQ</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Frequently Asked Questions</h1>
                </div>
                <div className="single-accordion">
                    <div className="accordion-style-three">
                        <div className="accordion" id="accordionthree">
                            {
                                FAQ.map((it, ind) => {
                                    return (
                                        <div className="card" key={ind} onClick={() => setActiveIndex(ind)}>
                                            <div className="card-header" style={{
                                                backgroundColor: ind === activeIndex ? "#86b817" : "",
                                                border: ind === activeIndex ? '2px solid' : "",
                                                borderColor: ind === activeIndex ? "#86b817" : ""
                                            }} id={"headingEleven" + ind}>
                                                <a href={"#collapseEleven" + ind} data-bs-toggle="collapse" role="button" aria-expanded={ind === 0 ? "true" : "false"}
                                                    style={{
                                                        color: ind === activeIndex ? "#ffffff" : "#000000",
                                                        fontFamily: 'initial',
                                                    }}
                                                >{it?.question}</a>
                                            </div>
                                            <div id={"collapseEleven" + ind} className={`collapse ${ind === 0 ? 'show' : 'hide'}`} data-bs-parent="#accordionthree">
                                                <div className="card-body">
                                                    <p className="text" style={{ fontFamily: 'initial' }}>
                                                        {it?.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqComp