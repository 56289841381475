import api from '../services/Axios';
import Cookie from '../utils/Cookie';


export const login = (payload) => {
  return api.post('auth/signin', payload).then(response => response)
    .catch(err => err);
};

export const loginForGoogle = (payload) => {
  return api.post('google/login', payload).then(response => response)
    .catch(err => err);
};

export const register = (payload) => {
  return api.post('auth/reg', payload).then(response => response)
    .catch(err => err);
};

export const GoogleRegister = (payload) => {
  return api.post('google/register', payload).then(response => response)
    .catch(err => err);
};

// -------------------------------------------------User Information------------------------------------------
export const ME = () => {
  const header = {
    headers: { 'Authorization': `Bearer ${Cookie.getCookie('_tokenAuth')}` }
  }
  return api.get('controller/user/me', header).then(response => response)
    .catch(err => err);
};

// ----------------------------------------- Reviews -----------------------------------------------------------

export const GetReviews = (payload) => {
  console.log('payload', payload)
  return api.post(`/user/get-review`, payload).then(response => response.data)
    .catch(err => err);
};
export const GetBlogs = () => {
  return api.get(`/admin/blog`).then(response => response.data)
    .catch(err => err);
};
export const BlogDetails = (id) => {
  return api.get(`/admin/blog/${id}`).then(response => response.data)
    .catch(err => err);
};
export const EnqueryNow = (payload) => {
  // const header = {
  //   headers: { 'Authorization': `Bearer ${Cookie.getCookie('_tokenAuth')}` }
  // }
  return api.post('/user/sendmail', payload).then(response => response.data)
    .catch(err => err);
};