/* eslint-disable radix */
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const UserGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  

  if (!isAuthenticated && user === null) {
    return <Navigate to="/login" />;
  }
  
  return (
    <>
      {children}
    </>
  );
};

UserGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserGuard;
