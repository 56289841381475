import React from 'react';
import PropTypes from 'prop-types';
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';

const SiteLayout = ({ children }) => {

  
  return (
    <main>
      <div className="authLogin">
        <SiteHeader />
        
        {children}
        <SiteFooter />
      </div>
    </main>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteLayout;
