import React from 'react'
import { Link } from 'react-router-dom'
import Destination1 from '../../assets/images/jhilimili12mile.jpg'
import Destination2 from '../../assets/images/Talberia-Lake.jpg'
import Destination3 from '../../assets/images/Burudi.jpeg'
import Destination4 from '../../assets/images/Ajodhya.jpg'

function DestinationComp() {
    return (
        <div className="container-xxl py-5 destination">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Destination</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Popular Destination</h1>
                </div>
                <div className="row g-3">
                    <div className="col-lg-7 col-md-6">
                        <div className="row g-3">
                            <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                <Link className="position-relative d-block overflow-hidden" href="">
                                    <img className="img-fluid" src={Destination1} alt="" />
                                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">30% OFF</div>
                                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Jhilimili 12 mile Forest</div>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                <Link className="position-relative d-block overflow-hidden" href="">
                                    <img className="img-fluid" src={Destination2} alt="" />
                                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div>
                                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Talberia Lake</div>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                <Link className="position-relative d-block overflow-hidden" href="">
                                    <img className="img-fluid" src={Destination3} alt="" />
                                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">35% OFF</div>
                                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Burudi Lake</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{ "min-height": "350px" }}>
                        <Link className="position-relative d-block h-100 overflow-hidden" href="">
                            <img className="img-fluid position-absolute w-100 h-100" src={Destination4} alt="" style={{ "object-fit": "cover" }} />
                            <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">20% OFF</div>
                            <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Ajodhya</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DestinationComp