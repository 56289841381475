import React from 'react'
import Team1 from '../../assets/images/samiran.jpeg';
import Team2 from '../../assets/images/mili.jpeg'
function TeamComp() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Travel Guide</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Meet Our Guide</h1>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="overflow-hidden">
                                <img className="img-fluid" src={Team1} alt="" />
                            </div>
                            <div className="position-relative d-flex justify-content-center" style={{ marginTop: "-19px" }}>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            </div>
                            <div className="text-center p-4">
                                <h5 className="mb-0" style={{ fontFamily: 'cursive', fontWeight: 'bolder' }}>Samiran Maity</h5>
                                <small>Main Guide & Activity Specialist</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="team-item">
                            <div className="overflow-hidden">
                                <img className="img-fluid" src={Team2} alt="" />
                            </div>
                            <div className="position-relative d-flex justify-content-center" style={{ marginTop: "-19px" }}>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            </div>
                            <div className="text-center p-4">
                                <h5 className="mb-0" style={{ fontFamily: 'cursive', fontWeight: 'bolder' }}>Mohua Mohanty</h5>
                                <small>Co Guide</small>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="team-item">
                            <div className="overflow-hidden">
                                <img className="img-fluid" src={Team1} alt="" />
                            </div>
                            <div className="position-relative d-flex justify-content-center" style={{ marginTop: "-19px" }}>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            </div>
                            <div className="text-center p-4">
                                <h5 className="mb-0">Full Name</h5>
                                <small>Designation</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="team-item">
                            <div className="overflow-hidden">
                                <img className="img-fluid" src={Team1} alt="" />
                            </div>
                            <div className="position-relative d-flex justify-content-center" style={{ marginTop: "-19px" }}>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            </div>
                            <div className="text-center p-4">
                                <h5 className="mb-0">Full Name</h5>
                                <small>Designation</small>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TeamComp