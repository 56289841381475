import React from 'react';
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth';
import Cookie from '../utils/Cookie';

function SiteHeader() {
  const navigate = useNavigate();
  const { Logout } = useAuth();

  return (
    <div className="authHeader">
      <div className="custom-container">
        <div className="authHeader-wrapper">
          <div className="authHeader_logo">
            <button onClick={() => { navigate('/') }}>
              HOME
            </button>
          </div>
          <div className="authHeader_rg">
            <ul className="authHeader_nav">
              {Cookie.getCookie('_tokenAuth') ?
                <li className="signIn">
                  <a onClick={() => { Logout(); navigate('/login') }}>Logout</a>
                </li>
                :
                <li className="signIn">
                  <button onClick={() => { navigate('/login') }} className="custom-btn-skyblue">
                    Sign In</button></li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

SiteHeader.propTypes = {};

export default SiteHeader;
