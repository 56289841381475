import React from 'react'

function ServiceComp() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Services</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Our Services</h1>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Trekking and Camping</h5>
                                <p style={{ fontFamily: 'initial' }}>Tracking in hills and camping in the forest.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-hotel text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Hotel Reservation</h5>
                                <p style={{ fontFamily: 'initial' }}>Researv our hotel with upto 20% off</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-user text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Travel Guides</h5>
                                <p style={{ fontFamily: 'initial' }}>Meet with our travel guide, they will help you to explore spot</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-cog text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Car and Bike facility.</h5>
                                <p style={{ fontFamily: 'initial' }}>Rent out car and bikes and explore location</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }} >24/7 Service.</h5>
                                <p style={{ fontFamily: 'initial' }}>Enjoy peace of mind with our 24/7 service, always ready to assist you whenever you need</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item rounded pt-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-hotel text-primary mb-4"></i>
                                <h5 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Tribal food.</h5>
                                <p style={{ fontFamily: 'initial' }}>Indulge in the rich flavors of tribal food, where each dish tells a story of tradition and culture.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ServiceComp