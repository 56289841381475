import React from 'react';
import { Link } from 'react-router-dom';
import PackageOne from '../assets/images/pancharatna-temple.jpg'
import PackageTwo from '../assets/images/Burudi-dam.jpeg'
import PackageThree from '../assets/images/package-3.jpg'
import Destination1 from '../assets/images/jhilimili12mile.jpg'
import Destination2 from '../assets/images/Talberia-Lake.jpg'
import Destination4 from '../assets/images/Mukutmonupur.png'

const SiteFooter = () => {
  const handlePhoneCall = (phoneNumber) => {
    // Perform any custom logic here before making the call
    console.log(`Calling ${phoneNumber}`);

    // Trigger the phone call by setting the window location to the tel link
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (emailAddress, subject, body) => {
    // You can perform any custom logic here before opening the mail client
    console.log(`Sending an email to ${emailAddress}`);

    // Construct the mailto link with subject and body
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the mail client
    window.location.href = mailtoLink;
  };
  return (
    <>
      <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Company</h4>
              <Link className="btn btn-link" to="/about">About Us</Link>
              <Link className="btn btn-link" to="/contact">Contact Us</Link>
              <Link className="btn btn-link" href="">Privacy Policy</Link>
              <Link className="btn btn-link" href="">Terms & Condition</Link>
              <Link className="btn btn-link" to="/faq">FAQs & Help</Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Contact</h4>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Jhargram,West Bengal, India</p>
              <p className="mb-2" onClick={() => handlePhoneCall('9064800743')} style={{ cursor: 'pointer' }}><i className="fa fa-phone-alt me-3"></i>+91 9064800743</p>
              <p className="mb-2" onClick={() => handleEmailClick('lalpaharirdeshofficial@gmail.com')} style={{ cursor: 'pointer' }}><i className="fa fa-envelope me-3"></i>lalpaharirdeshofficial@gmail.com</p>
              <div className="d-flex pt-2">
                {/* <Link className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></Link> */}
                <Link className="btn btn-outline-light btn-social" to="https://www.facebook.com/share/uKFEHH7Up6qsD3Lx/?mibextid=LQQJ4d" target='_blank'><i className="fab fa-facebook-f"></i></Link>
                <Link className="btn btn-outline-light btn-social" to="https://www.youtube.com/@lalpaharirdeshofficial" target='_blank'><i className="fab fa-youtube"></i></Link>
                <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" to="https://www.instagram.com/lalpaharirdesh_official?igsh=MThxc3I3YnVkdXhwMA%3D%3D&utm_source=qr" target='_blank'><i className="fab fa-instagram fw-normal"></i></Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}> Gallery</h4>
              <div className="row g-2 pt-2">
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={PackageOne} alt="" />
                </div>
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={PackageTwo} alt="" />
                </div>
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={PackageThree} alt="" />
                </div>
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={Destination1} alt="" />
                </div>
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={Destination2} alt="" />
                </div>
                <div className="col-4">
                  <img className="img-fluid bg-light p-1" src={Destination4} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Newsletter</h4>
              <p>Stay updated with the latest travel deals, tips, and destination highlights by subscribing to our newsletter!</p>
              <div className="position-relative mx-auto" style={{ "max-width": "400px" }}>
                <input className="form-control border-primary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <Link className="border-bottom" href="http://lalpaharirdesh.in">lalpaharirdesh.in</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="">Cookies</Link>
                  <Link to="/contact">Help</Link>
                  <Link to="/faq">FQAs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></Link>

    </>
  );
};


export default SiteFooter;
