import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'
import FaqComp from '../../components/HomeComponents/FaqComp'
import Package1 from '../../assets/images/pancharatna-temple.jpg';
import Package2 from '../../assets/images/Burudi-dam.jpeg';
import Package3 from '../../assets/images/dam-view.jpeg';

const PACKAGE = [
    {
        id: 1,
        location: 'Jhargram',
        timings: '1 Night 2 Days',
        person: '2',
        title: 'Starting at ₹3000',
        description: 'Jhargram 1 Night/2 Days Tour: Explore the Scenic Beauty of Jungle Mahal',
        longDescription: ` 

        <h2>Day 1: Arrival & Exploration</h2>
        <ul>
            <li>Arrive in Jhargram and check into your hotel, eco-resort, treehouse, or campsite. Enjoy a bonfire in the evening.</li>
            <li>Begin your tour with a visit to <strong>Gadrasini Hill</strong>. Enjoy a peaceful hike and take in panoramic views of the surrounding forests.</li>
            <li>Next, head to the picturesque <strong>Gagra Waterfall</strong>, where the cascading waters amidst lush greenery create a perfect spot for relaxation and photography.</li>
            <li>In the evening, explore the tranquil <strong>Khadarani Lake</strong> to unwind. Watch the sunset reflect off the calm waters.</li>
        </ul>

        <h2>Day 2: Wildlife & Nature Experience</h2>
        <ul>
            <li>After breakfast, set out for <strong>Dhangikusum Lal Jol</strong>, a hidden gem nestled in the Jungle Mahal area, known for its rich flora and natural beauty.</li>
            <li>Later, visit the <strong>Deer Zoological Park</strong>, where you can observe various species of deer and experience the region's wildlife.</li>
            <li>End your tour with a leisurely stroll through the forested areas, taking in the fresh air and peaceful surroundings before returning to your accommodation.</li>
        </ul>`,
        image: Package1,
        facility: ['Forest camps with bonfire.', 'Treehouse, Matir Bari, resorts surrounded by nature', 'AC & NON AC Resorts', 'Bike rides, Car services.', 'Food & Swiming Pool', 'Activities for couples, friends and family']
    },
    {
        id: 2,
        location: 'Gatsila',
        timings: '2 Days 2 Night',
        person: '2',
        title: 'Starting at ₹5000',
        description: 'Ghatshila 2 Night/2 Days Tour: Discover the Charm of Nature and Heritage',
        longDescription: `<h2>Day 1: Arrival & Natural Wonders</h2>
        <ul>
            <li>Arrive in Ghatshila and settle into your hotel or resort.</li>
            <li>Begin your day with a peaceful visit to the <strong>Subarnarekha River</strong>, where you can enjoy the serene riverside views and the calming sound of the water flowing through the scenic landscape.</li>
            <li>Head to the picturesque <strong>Burudi Dam</strong>, surrounded by hills and lush greenery, providing a perfect backdrop for a relaxing time in nature.</li>
            <li>Continue your exploration with a trip to the enchanting <strong>Dharagiri Waterfall</strong>, where the cascading water creates a stunning natural sight and a peaceful atmosphere ideal for photography or simply soaking in the beauty.</li>
            <li>In the evening, visit the serene <strong>Ghatshila Surja Mandir</strong>, a temple dedicated to the Sun God, offering stunning views of the surrounding areas during sunset.</li>
        </ul>

        <h2>Day 2: Heritage & Cultural Exploration</h2>
        <ul>
            <li>After breakfast, visit the <strong>Galudi Barrage</strong>, a peaceful spot offering scenic views of the river and surrounding hills, perfect for a morning walk or quiet reflection.</li>
            <li>Take a trip back in time with a visit to the <strong>Famous Bengali Writer Bibhutibhushan Bandopadhyay's House</strong>, where you can explore the memories of the iconic author and visit the nearby <strong>Poetry Point</strong>, a place of literary inspiration.</li>
            <li>Stop by the historic <strong>Rankani Mandir</strong>, a sacred temple known for its spiritual significance and cultural heritage in the region.</li>
            <li>Finally, explore the grandeur of <strong>Ghatshila Raj Palace</strong>, an architectural marvel that speaks of the royal history and heritage of Ghatshila, adding a touch of elegance to your journey.</li>
        </ul>`,
        image: Package2,
        facility: ['Forest camps with bonfire.', 'Treehouse, Matir Bari, resorts surrounded by nature', 'AC & NON AC Resorts', 'Bike rides, Car services.', 'Food & Swiming Pool', 'Activities for couples, friends and family']

    },
    {
        id: 3,
        location: 'Mukutmanipur',
        timings: '2 days 1 night',
        person: '2',
        title: 'Starting at ₹4000',
        description: '1 Night/2 Days Tour: Discover the Charm of Nature and Heritage',
        longDescription: `        <h2>Day 1: Arrival & Natural Wonders</h2>
        <ul>
            <li>Arrive in Ghatshila and settle into your hotel or resort.</li>
            <li>Begin your day with a peaceful visit to the <strong>Subarnarekha River</strong>, where you can enjoy the serene riverside views and the calming sound of the water flowing through the scenic landscape.</li>
            <li>Head to the picturesque <strong>Burudi Dam</strong>, surrounded by hills and lush greenery, providing a perfect backdrop for a relaxing time in nature.</li>
            <li>Continue your exploration with a trip to the enchanting <strong>Dharagiri Waterfall</strong>, where the cascading water creates a stunning natural sight and a peaceful atmosphere ideal for photography or simply soaking in the beauty.</li>
            <li>In the evening, visit the serene <strong>Ghatshila Surja Mandir</strong>, a temple dedicated to the Sun God, offering stunning views of the surrounding areas during sunset.</li>
        </ul>

        <h2>Day 2: Heritage & Cultural Exploration</h2>
        <ul>
            <li>After breakfast, visit the <strong>Galudi Barrage</strong>, a peaceful spot offering scenic views of the river and surrounding hills, perfect for a morning walk or quiet reflection.</li>
            <li>Take a trip back in time with a visit to the <strong>Famous Bengali Writer Bibhutibhushan Bandopadhyay's House</strong>, where you can explore the memories of the iconic author and visit the nearby <strong>Poetry Point</strong>, a place of literary inspiration.</li>
            <li>Stop by the historic <strong>Rankani Mandir</strong>, a sacred temple known for its spiritual significance and cultural heritage in the region.</li>
            <li>Finally, explore the grandeur of <strong>Ghatshila Raj Palace</strong>, an architectural marvel that speaks of the royal history and heritage of Ghatshila, adding a touch of elegance to your journey.</li>
        </ul>`,
        image: Package3,
        facility: ['Forest camps with bonfire.', 'Treehouse, Matir Bari, resorts surrounded by nature', 'AC & NON AC Resorts', 'Bike rides, Car services.', 'Food & Swiming Pool', 'Activities for couples, friends and family']

    },
]
function PackageDetails() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const packageId = queryParams.get("packageId");
    const [packageDetails, setPackageDetails] = useState(null)

    useEffect(() => {
        if (packageId) {
            let filterArr = PACKAGE.filter((it) => it?.id == packageId)
            setPackageDetails(filterArr[0])
        }
    }, [packageId])
    return (
        <>
            <Helmet>
                <title>Package Details</title>
            </Helmet>
            <>
                <CustomHeader>
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Package Details</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Package Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card mb-4">
                                <a href="#!"><img className="card-img-top" src={packageDetails?.image} alt="..." /></a>
                                <div className="card-body">
                                    <div className="small text-muted" >{packageDetails?.title}</div>
                                    <h2 className="card-title" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>{packageDetails?.description}</h2>
                                    <div className="card-text" dangerouslySetInnerHTML={{ __html: packageDetails?.longDescription }} />
                                    <Link className="btn btn-primary" to="/booking">Book Now →</Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-header">Search</div>
                                <div className="card-body">
                                    <div className="input-group">
                                        <input className="form-control" type="text" placeholder="Enter search term..." aria-label="Enter search term..." aria-describedby="button-search" />
                                        <button className="btn btn-primary" id="button-search" type="button">Go!</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">Facility</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ul className="list-unstyled mb-0" style={{ listStyleType: 'disc', paddingLeft: '10px', fontFamily: 'fantasy' }}>
                                                {
                                                    packageDetails?.facility?.map((it, ind) => {
                                                        return (
                                                            <li key={ind}>
                                                                <a href="#!" >
                                                                    <strong>{it}</strong>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">Timings</div>
                                <div className="card-body">{packageDetails?.timings}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <FaqComp />
            </>
        </>
    )
}

export default PackageDetails;