import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GetBlogs } from '../../actions/authAction'
import moment from 'moment'

function BlogComp() {
    const [blogs, setBlogs] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getBloghandler()
    }, [])
    const getBloghandler = async () => {
        let result = await GetBlogs()
        console.log('result', result)
        if (result && result.status) {
            setBlogs(result?.data)
        }
    }

    const truncateContent = (htmlContent, length) => {
        if (htmlContent) {
            const plainText = htmlContent.replace(/<[^>]+>/g, ""); // Strip HTML tags
            return plainText.length > length
                ? plainText.slice(0, length) + "..."
                : plainText;
        }
    };

    return (
        <div>
            <section className="blog-area pb-5">
                <div className="container">
                    <div className="text-center">
                        <h6 className="section-title bg-white text-center text-primary px-3">Travel Articles</h6>
                        <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Popular Blogs</h1>
                    </div>
                    <div className="row justify-content-center">
                        {
                            blogs?.length > 0 && blogs.map((it, ind) => {
                                return (
                                    <div className="col-lg-4 col-md-8 col-sm-10" key={ind}>
                                        <div className="single-blog blog-style-one">
                                            <div className="blog-image">
                                                <img src={it?.image} alt="Blog" />
                                                <a href="javascript:void(0)" className="category"> {it?.blogCategory[0]?.name}</a>
                                            </div>
                                            <div className="blog-content">
                                                <h5 className="blog-title">
                                                    {it?.title}
                                                </h5>
                                                <span><i className="lni lni-calendar"></i>{moment(it?.createdOn).format('ll')}</span>
                                                {/* <span><i className="lni lni-comments-alt"></i> 24 Comment</span> */}
                                                <p className="text" dangerouslySetInnerHTML={{ __html: truncateContent(it?.description, 150) }} />
                                                <Link className="more" to={`/blog?blogId=${it?._id}`}>READ MORE</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlogComp