import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AboutComp from '../../../components/HomeComponents/AboutComp';
import ServiceComp from '../../../components/HomeComponents/ServiceComp';
import DestinationComp from '../../../components/HomeComponents/DestinationComp';
import PackageComp from '../../../components/HomeComponents/PackageComp';
import BookingComp from '../../../components/HomeComponents/BookingComp';
import ProcessComp from '../../../components/HomeComponents/ProcessComp';
import TeamComp from '../../../components/HomeComponents/TeamComp';
import Testimonial from '../../../components/HomeComponents/Testimonial';
import CustomHeader from '../../../components/CustomHeader';
import BlogComp from '../../../components/HomeComponents/BlogComp';
import FaqComp from '../../../components/HomeComponents/FaqComp';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Lalpaharir Desh</title>
      </Helmet>
      <>
        {/* <div className="container-fluid bg-primary py-5 mb-5 hero-header"> */}
        <CustomHeader>
          <div className="container py-5">
            <div className="row justify-content-center py-5">
              <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                <h1 className="display-3 text-white mb-3 animated slideInDown" style={{ fontFamily: 'initial', fontWeight: 'bolder' }} >Enjoy Your Vacation With Us</h1>
                <p className="fs-4 text-white mb-4 animated slideInDown" style={{ fontFamily: 'cursive' }}>Step into the heart of Lalpaharir Desh and leave the ordinary behind.</p>
                <div className="position-relative w-75 mx-auto animated slideInDown">
                  <input className="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Eg: Jhargram" />
                  <button type="button" className="btn btn-primary rounded-pill py-2 px-4 position-absolute top-0 end-0 me-2" style={{ "margin-top": "7px" }}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </CustomHeader>
        {/* </div> */}

        <AboutComp />
        <ServiceComp />
        <DestinationComp />
        <PackageComp />
        <BookingComp />
        <ProcessComp />
        <TeamComp />
        <Testimonial />
        <BlogComp />
        <FaqComp />
      </>
    </>
  );
}

export default LandingPage;
