import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { EnqueryNow } from '../../actions/authAction'
import toast from 'react-hot-toast'
import LoadingScreen from '../LoadingScreen'

function BookingComp() {
    const [formdata, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [loading, setLoading] = useState(false)
    const handleEnquery = async () => {
        if (formdata.name.trim() === "" || formdata.email.trim() === "" || formdata.phone.trim() === "") {
            return toast.error('Fill All fields')
        }
        setLoading(true)
        let data = {
            name: formdata.name,
            email: formdata.email,
            phone: formdata.phone,
            message: formdata.message
        }
        let result = await EnqueryNow(data)
        if (result && result?.status) {
            console.log('result', result)
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: ''
            })
            toast.success('Thank you for your interest, Our team will contact you  soon !!')
            setLoading(false)

        } else {
            toast.error('Something wromg!')
            setLoading(false)

        }
    }
    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            {/* {
                loading && <LoadingScreen />
            } */}
            <div className="container">
                <div className="booking p-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-6 text-white">
                            <h6 className="text-white text-uppercase" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Booking</h6>
                            <h1 className="text-white mb-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Online Booking</h1>
                            <p className="mb-4" style={{ fontFamily: 'initial' }}>It gives me great pleasure to introduce you to Lalpaharir Desh Travel, your gateway to the breathtaking landscapes of India’s hidden gems. Whether you're dreaming of lush hills, serene beaches, or culturally rich destinations, our website is here to make that dream a reality.</p>
                            <p className="mb-4" style={{ fontFamily: 'initial' }}>At Lalpaharir Desh Travel, we believe in the beauty of personalized experiences. Our platform is designed to offer hassle-free bookings, customized itineraries, and expert travel tips to ensure every journey is unforgettable. With just a few clicks, you'll have access to curated travel plans, local insights, and exclusive deals.</p>
                            <Link className="btn btn-outline-light py-3 px-5 mt-2" href="" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Read More</Link>
                        </div>
                        <div className="col-md-6">
                            <h1 className="text-white mb-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Book A Tour</h1>
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-control">
                                            <input type="text" className="form-control bg-transparent" id="name" placeholder="Your Name" value={formdata.name}
                                                onChange={(e) => setFormData((prev) => ({
                                                    ...prev,
                                                    name: e.target.value
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-control">
                                            <input type="email" className="form-control bg-transparent" id="email" placeholder="Your Email"
                                                value={formdata.email}
                                                onChange={(e) => setFormData((prev) => ({
                                                    ...prev,
                                                    email: e.target.value
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-control" >
                                            <input type="text" className="form-control bg-transparent datetimepicker-input" placeholder="Phone Number"
                                                value={formdata.phone}
                                                onChange={(e) => setFormData((prev) => ({
                                                    ...prev,
                                                    phone: e.target.value
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-control">
                                            <select className="form-select bg-transparent" id="select1">
                                                <option value="1">Destination 1</option>
                                                <option value="2">Destination 2</option>
                                                <option value="3">Destination 3</option>
                                            </select>
                                            <label for="select1">Destination</label>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="form-control">
                                            <textarea className="form-control bg-transparent" placeholder="Special Request" id="message" style={{ "height": "100px" }}
                                                value={formdata.message}
                                                onChange={(e) => setFormData((prev) => ({
                                                    ...prev,
                                                    message: e.target.value
                                                }))}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-outline-light w-100 py-3" type="button" onClick={handleEnquery} disabled={loading}>{loading ? 'Loading....' : "Book Now"}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingComp