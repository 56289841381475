import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import HomeHeader from '../../components/HomeHeader';
import SiteFooter from '../../components/SiteFooter';

const MainLayout = ({ children }) => {

  return (
    <>
      <Helmet>

      </Helmet>


      <div className='body'>
        <main className={`mainDashboard`}>
          <div className="mainDashboard-right">
            <HomeHeader />
            <div className="mainDashboard-body">
              <div >
                {children}
              </div>
            </div>
            <SiteFooter />
          </div>
        </main>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
