/* eslint-disable radix */
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  // if (!isAuthenticated && user === null) {
  //   return <Navigate to="/login" />;
  // }

  if (isAuthenticated && user !== null) {
    return <Navigate to="/user/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
