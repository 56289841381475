import React from 'react'

function ProcessComp() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Process</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>3 Easy Steps</h1>
                </div>
                <div className="row gy-5 gx-4 justify-content-center">
                    <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="position-relative border border-primary pt-5 pb-4 px-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ "width": "100px", height: "100px" }}>
                                <i className="fa fa-globe fa-3x text-white"></i>
                            </div>
                            <h5 className="mt-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Choose A Destination</h5>
                            <hr className="w-25 mx-auto bg-primary mb-1" />
                            <hr className="w-50 mx-auto bg-primary mt-0" />
                            <p className="mb-0">Choose a destination that aligns with your passions, budget, and travel goals for a truly unforgettable experience.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="position-relative border border-primary pt-5 pb-4 px-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ "width": "100px", height: "100px" }}>
                                <i className="fa fa-dollar-sign fa-3x text-white"></i>
                            </div>
                            <h5 className="mt-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Pay Advance</h5>
                            <hr className="w-25 mx-auto bg-primary mb-1" />
                            <hr className="w-50 mx-auto bg-primary mt-0" />
                            <p className="mb-0">Secure your booking by paying an advance, ensuring your spot is reserved and confirmed for a hassle-free travel experience.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="position-relative border border-primary pt-5 pb-4 px-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ "width": "100px", height: "100px" }}>
                                <i className="fa fa-plane fa-3x text-white"></i>
                            </div>
                            <h5 className="mt-4" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Travel Today</h5>
                            <hr className="w-25 mx-auto bg-primary mb-1" />
                            <hr className="w-50 mx-auto bg-primary mt-0" />
                            <p className="mb-0">With the Travel Today option, book your trip, make necessary payments, and embark on your journey immediately, all in a few simple steps for same-day travel convenience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcessComp