import React from 'react'
import { Outlet } from 'react-router-dom'
import droneImage from '../assets/images/Hillview.png'
function CustomHeader({ children }) {
    return (
        <div className="hero-header">
            {/* <video muted id="bg-video" autoPlay loop playsinline controls={false}>
                <source src={droneVideo} type="video/mp4" />
            </video> */}
            <img src={droneImage} id="bg-video" alt='drone' />
            <div className="hero-content">
                {/* <Outlet /> */}
                {children}
            </div>
        </div>
    )
}

export default CustomHeader