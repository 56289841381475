import React, { useEffect, useState } from 'react'
import Testimonial1 from '../../assets/images/testimonial-1.jpg'
import Carousel from 'react-bootstrap/Carousel';
import { GetReviews } from '../../actions/authAction';
import { Link } from 'react-router-dom';

function Testimonial() {
    const [allreviews, setAllReviews] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getReviewshandler()
    }, [])
    const getReviewshandler = async () => {
        let data = {
            place_id: "ChIJrVCW_I4m9jkRIUFdiikwMEE"
        }
        let result = await GetReviews(data)
        console.log('result', result)
        if (result && result.status) {
            setAllReviews(result?.data?.reviews)
        }
    }
    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="text-center">
                    <h6 className="section-title bg-white text-center text-primary px-3" >Testimonial</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Our Clients Say!!!</h1>

                    <div className="text-center p-4">
                        <div className="mb-3">
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                        </div>
                        <div className="d-flex justify-content-center mb-2">
                            <Link to={`https://g.page/r/CdpJj1Ff-6o5EBI/review`} target='_blank' className="btn btn-sm btn-primary px-3 border-end" style={{ "border-radius": "30px 0 0 30px;" }}>Review Us On Google</Link>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel testimonial-carousel position-relative">
                    <Carousel indicators={false}>
                        {
                            allreviews.length > 0 && allreviews.map((it, ind) => {
                                return (
                                    <Carousel.Item key={ind}>
                                        <div className="testimonial-item bg-white text-center border p-4">
                                            <img className="bg-white rounded-circle shadow p-1 mx-auto mb-3" alt='' src={it?.profile_photo_url} style={{ width: "80px", height: "80px" }} />
                                            <h5 className="mb-0">{it?.author_name}</h5>
                                            <p>{it?.relative_time_description}</p>
                                            <p className="mb-0">{it?.text}</p>
                                        </div>
                                    </Carousel.Item>
                                )
                            })
                        }

                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Testimonial