import React from 'react'
import { Link } from 'react-router-dom'
import Package1 from '../../assets/images/pancharatna-temple.jpg';
import Package2 from '../../assets/images/Burudi-dam.jpeg';
import Package3 from '../../assets/images/dam-view.jpeg';

const PACKAGE = [
    {
        id: 1,
        location: 'Jhargram',
        timings: '1 Night 2 Days',
        person: '2',
        title: 'Starting at ₹3000',
        description: 'Jhargram 1 Night/2 Days Tour: Explore the Scenic Beauty of Jungle Mahal',
        longDescription: `Day 1: Arrival & Exploration
* Arrive in Jhargram and check into your hotel , eco-resort, treehouse or Campsite enjoy bonfire.
* Begin your tour with a visit to Gadrasini Hill, where you can enjoy a peaceful hike and take in panoramic views of the surrounding forests.
* Next, head to the picturesque Gagra Waterfall, where the cascading waters amidst lush greenery create a perfect spot for relaxation and photography.
* In the evening, explore the tranquil Khadarani Lake, where you can unwind and enjoy the serene atmosphere, watching the sunset reflect off the calm waters.
Day 2: Wildlife & Nature Experience
* After breakfast, set out for Dhangikusum Lal Jol, a hidden gem nestled in the Jungle Mahal area, known for its rich flora and natural beauty.
* Later, visit the Deer Zoological Park, where you can observe various species of deer and experience the wildlife that thrives in the region.
* End your tour with a leisurely stroll through the forested areas, taking in the fresh air and peaceful surroundings before returning to your accommodation.`,
        image: Package1
    },
    {
        id: 2,
        location: 'Gatsila',
        timings: '2 Days 2 Night',
        person: '2',
        title: 'Starting at ₹5000',
        description: 'Ghatshila 2 Night/2 Days Tour: Discover the Charm of Nature and Heritage',
        longDescription: `Day 1: Arrival & Natural Wonders
* Arrive in Ghatshila and settle into your hotel or resort.
* Begin your day with a peaceful visit to the Subarnarekha River, where you can enjoy the serene riverside views and the calming sound of the water flowing through the scenic landscape.
* Head to the picturesque Burudi Dam, surrounded by hills and lush greenery, providing a perfect backdrop for a relaxing time in nature.
* Continue your exploration with a trip to the enchanting Dharagiri Waterfall, where the cascading water creates a stunning natural sight and a peaceful atmosphere ideal for photography or simply soaking in the beauty.
* In the evening, visit the serene Ghatshila Surja Mandir, a temple dedicated to the Sun God, offering stunning views of the surrounding areas during sunset.
Day 2: Heritage & Cultural Exploration
* After breakfast, visit the Galudi Barrage, a peaceful spot offering scenic views of the river and surrounding hills, perfect for a morning walk or quiet reflection.
* Take a trip back in time with a visit to the Famous Bengali Writer Bibhutibhushan Bandopadhyay's House, where you can explore the memories of the iconic author and visit the nearby Poetry Point, a place of literary inspiration.
* Stop by the historic Rankani Mandir, a sacred temple known for its spiritual significance and cultural heritage in the region.
* Finally, explore the grandeur of Ghatshila Raj Palace, an architectural marvel that speaks of the royal history and heritage of Ghatshila, adding a touch of elegance to your journey.
This 1 Night/2 Days tour offers a perfect blend of natural beauty, spiritual landmarks, and cultural heritage, making Ghatshila a memorable escape into tranquility and history.`,
        image: Package2
    },
    {
        id: 3,
        location: 'Mukutmanipur',
        timings: '2 days 1 night',
        person: '2',
        title: 'Starting at ₹4000',
        description: '1 Night/2 Days Tour: Discover the Charm of Nature and Heritage',
        longDescription: `Day 1: Arrival & Natural Wonders
* Arrive in Ghatshila and settle into your hotel or resort.
* Begin your day with a peaceful visit to the Subarnarekha River, where you can enjoy the serene riverside views and the calming sound of the water flowing through the scenic landscape.
* Head to the picturesque Burudi Dam, surrounded by hills and lush greenery, providing a perfect backdrop for a relaxing time in nature.
* Continue your exploration with a trip to the enchanting Dharagiri Waterfall, where the cascading water creates a stunning natural sight and a peaceful atmosphere ideal for photography or simply soaking in the beauty.
* In the evening, visit the serene Ghatshila Surja Mandir, a temple dedicated to the Sun God, offering stunning views of the surrounding areas during sunset.
Day 2: Heritage & Cultural Exploration
* After breakfast, visit the Galudi Barrage, a peaceful spot offering scenic views of the river and surrounding hills, perfect for a morning walk or quiet reflection.
* Take a trip back in time with a visit to the Famous Bengali Writer Bibhutibhushan Bandopadhyay's House, where you can explore the memories of the iconic author and visit the nearby Poetry Point, a place of literary inspiration.
* Stop by the historic Rankani Mandir, a sacred temple known for its spiritual significance and cultural heritage in the region.
* Finally, explore the grandeur of Ghatshila Raj Palace, an architectural marvel that speaks of the royal history and heritage of Ghatshila, adding a touch of elegance to your journey.
This 1 Night/2 Days tour offers a perfect blend of natural beauty, spiritual landmarks, and cultural heritage, making Ghatshila a memorable escape into tranquility and history.`,
        image: Package3
    },
]
function PackageComp() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Packages</h6>
                    <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Awesome Packages</h1>
                </div>
                <div className="row g-4 justify-content-center">
                    {
                        PACKAGE.map((it, ind) => {
                            return (
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={ind}>
                                    <div className="package-item">
                                        <div className="overflow-hidden">
                                            <img className="img-fluid" src={it?.image} alt=""
                                                style={{
                                                    height: '271px',
                                                    width: '100%',
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex border-bottom">
                                            <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>{it?.location}</small>
                                            <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>{it?.timings}</small>
                                            <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>{it?.person} person</small>
                                        </div>
                                        <div className="text-center p-4">
                                            <h3 className="mb-0" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>{it?.title}</h3>
                                            <div className="mb-3">
                                                <small className="fa fa-star text-primary"></small>
                                                <small className="fa fa-star text-primary"></small>
                                                <small className="fa fa-star text-primary"></small>
                                                <small className="fa fa-star text-primary"></small>
                                                <small className="fa fa-star text-primary"></small>
                                            </div>
                                            <p>{it?.description}</p>
                                            <div className="d-flex justify-content-center mb-2">
                                                <Link to={`/details?packageId=${it?.id}`} className="btn btn-sm btn-primary px-3 border-end" style={{ "border-radius": "30px 0 0 30px;" }}>Read More</Link>
                                                <Link to="/booking" className="btn btn-sm btn-primary px-3" style={{ "border-radius": "0 30px 30px 0;" }}>Book Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default PackageComp