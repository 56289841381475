
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/LALPAHARIR.png'
const HomeHeader = () => {
  const location = useLocation()
  const [activetab, setActiveTab] = useState(0)
  const [menuShow, setMenuShow] = useState('')
  const [mobileMenu, setMobileMenu] = useState(false)

  const handlePhoneCall = (phoneNumber) => {
    // Perform any custom logic here before making the call
    console.log(`Calling ${phoneNumber}`);

    // Trigger the phone call by setting the window location to the tel link
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (emailAddress, subject, body) => {
    // You can perform any custom logic here before opening the mail client
    console.log(`Sending an email to ${emailAddress}`);

    // Construct the mailto link with subject and body
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the mail client
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center" style={{ "height": "45px" }}>
              <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>Jhargram,West Bengal, India</small>
              <small className="me-3 text-light" onClick={() => handlePhoneCall('9064800743')} style={{ cursor: 'pointer' }}><i className="fa fa-phone-alt me-2"></i>+91 9064800743</small>
              <small className="text-light" onClick={() => handleEmailClick('lalpaharirdeshofficial@gmail.com')} style={{ cursor: 'pointer' }}><i className="fa fa-envelope-open me-2"></i>lalpaharirdeshofficial@gmail.com</small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-inline-flex align-items-center" style={{ "height": "45px" }}>
              {/* <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" to=""><i className="fab fa-twitter fw-normal"></i></Link> */}
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" to="https://www.instagram.com/lalpaharirdesh_official?igsh=MThxc3I3YnVkdXhwMA%3D%3D&utm_source=qr" target='_blank'><i className="fab fa-facebook-f fw-normal"></i></Link>
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" to="https://www.facebook.com/share/uKFEHH7Up6qsD3Lx/?mibextid=LQQJ4d" target='_blank'><i className="fab fa-instagram fw-normal"></i></Link>
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" to="https://www.youtube.com/@lalpaharirdeshofficial" target='_blank'><i className="fab fa-youtube fw-normal"></i></Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            {/* <h1 className="text-primary m-0" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}><i className="fa fa-map-marker-alt me-3"></i>Lalpaharir Desh</h1> */}
            <img src={logo} alt="Logo" />
          </Link>
          <button className={`navbar-toggler ${!mobileMenu ? 'collapsed' : ''}`} type="button" onClick={() => setMobileMenu(!mobileMenu)}>
            <span className="fa fa-bars"></span>
          </button>
          <div className={`${!mobileMenu ? 'collapse' : ''} navbar-collapse ${mobileMenu ? 'show' : ''}`} >
            <div className="navbar-nav ms-auto py-0">
              <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/" className={`nav-item nav-link ${activetab === 0 ? 'active' : ''}`} onClick={() => { setActiveTab(0); setMobileMenu(false) }}>Home</Link>
              <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/about" className={`nav-item nav-link ${activetab === 1 ? 'active' : ''}`} onClick={() => { setActiveTab(1); setMobileMenu(false) }}>About</Link>
              <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/service" className={`nav-item nav-link ${activetab === 2 ? 'active' : ''}`} onClick={() => { setActiveTab(2); setMobileMenu(false) }}>Services</Link>
              <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/package" className={`nav-item nav-link ${activetab === 3 ? 'active' : ''}`} onClick={() => { setActiveTab(3); setMobileMenu(false) }}>Packages</Link>
              <div className={`nav-item dropdown ${menuShow}`} onMouseEnter={() => setMenuShow('show')} onMouseLeave={() => setMenuShow('')}>
                <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" >More</Link>
                <div className={`dropdown-menu m-0 ${menuShow}`}>
                  <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/destination" className={`dropdown-item ${location.pathname.includes("/destination") ? 'active' : ''}`} onClick={() => setMobileMenu(false)}>Destination</Link>
                  <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/booking" className={`dropdown-item ${location.pathname.includes("/booking") ? 'active' : ''}`} onClick={() => setMobileMenu(false)}>Booking</Link>
                  <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/team" className={`dropdown-item ${location.pathname.includes("/team") ? 'active' : ''}`} onClick={() => setMobileMenu(false)}>Travel Guides</Link>
                  <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/testimonial" className={`dropdown-item ${location.pathname.includes("/testimonial") ? 'active' : ''}`} onClick={() => setMobileMenu(false)}>Testimonial</Link>
                </div>
              </div>
              <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to="/contact" className={`nav-item nav-link ${activetab === 4 ? 'active' : ''}`} onClick={() => { setActiveTab(4); setMobileMenu(false) }}>Contact</Link>
            </div>
            <Link style={{ fontFamily: 'initial', fontWeight: 'bolder' }} to={'/booking'} className="btn btn-primary rounded-pill py-2 px-4">Enquiry</Link>
          </div>

        </nav>
      </div>
    </>
  );
};

HomeHeader.propTypes = {

};

export default HomeHeader;
